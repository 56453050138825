
import { Component, Vue } from 'vue-property-decorator';
import { Gopage } from '@/mixins/gopage';

@Component({
  components: {},
  mixins: [Gopage],
})
export default class InnerInformation extends Vue {
  private dataForm: any = {
    commonPic: '',
    commonTitle: '',
    commonContent: '',
    commonBgPic: '',
    commonDetailsPic: '',
    dataList: [],
  };

  private type: any = '';

  /**
   * @desc Vue 创建数据生命周期，获取验证码
   * @param1 暂无
   * @returns 暂无
   */
  created() {}

  showComponent(type, data: any) {
    this.type = type;
    this.dataForm = data;
  }
}
