
import { Component, Vue } from 'vue-property-decorator';
import { Gopage } from '@/mixins/gopage';
import ProductCentralSection from '@/views/productCenter/unit/productCentralSection.vue';
import ProductCentralSectionEnt from '@/views/productCenter/unit/productCentralSectionEnt.vue';
import InnerInformation from '@/views/productCenter/innerInformation/innerInformation.vue';
import BottomModule from '@/components/bottomModule/bottomModule.vue';

@Component({
  components: { BottomModule, InnerInformation, ProductCentralSectionEnt, ProductCentralSection },
  mixins: [Gopage],
})
export default class Login extends Vue {
  private activeName: any = 'third';
  private type: any = 'gov';
  private isBody: any = true; // 是否展示产品中心主体内容
  private dropdownIcon: any = 'el-icon-s-unfold';

  /**
   * @desc Vue 创建数据生命周期，获取验证码
   * @param1 暂无
   * @returns 暂无
   */
  created() {}

  mounted() {
    this.type = this.$route.params.type ? this.$route.params.type : 'gov';
    if (this.type === 'ent') {
      const arr: any = document.getElementsByClassName('trade_tag') as any;
      arr[0].classList.add('active');
    }

    if (this.$route.params.sysType) {
      if (this.$route.params.sysType === '安全生产综合监管系统' || this.$route.params.sysType === '高危企业风险监测预警系统' || this.$route.params.sysType === '安全生产教育培训监管系统' || this.$route.params.sysType === '安全生产责任清单制管理系统') {
        this.centralResponse(this.$route.params.sysType, 'gov');
      }
      if (this.$route.params.sysType === '履职清单管理系统' || this.$route.params.sysType === '设备全生命周期管理系统' || this.$route.params.sysType === '职工教育培训管理系统') {
        this.centralResponse(this.$route.params.sysType, 'ent');
      }
    }
  }

  // beforeDestroy() {
  //   window.scrollTo({ top: 0 });
  // }

  visibleChange(e: any) {
    this.dropdownIcon = e ? 'el-icon-close' : 'el-icon-s-unfold';
  }

  /**
      @description: 滑块点击事件
      @author: cxf
      @update: cxf 2023/3/20 9:30
   **/
  changeStyle(type: any) {
    this.type = type;
    const arr: any = document.getElementsByClassName('trade_tag') as any;
    if (type === 'gov') {
      arr[0].classList.remove('active');
    } else {
      arr[0].classList.add('active');
    }
  }

  handleResponse() {
    this.isBody = true;
    if (this.$route.params.sysType && this.$route.params.router === 'login') {
      this.$router.push('/index');
    }

    if (this.$route.params.sysType && this.$route.params.router === 'solution') {
      this.$router.push('/solution');
    }
  }

  // 详细内容组件触发事件
  centralResponse(data: any, type: any) {
    this.isBody = false;
    (this.$refs['innerInfo'] as any).changeLabel(data, type);
  }
}
