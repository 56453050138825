
import { Component, Vue } from 'vue-property-decorator';
import CommonComponents from '@/views/productCenter/innerInformation/commonComponents/commonComponents.vue';
import { Gopage } from '@/mixins/gopage';
import FloatBtn from '@/components/floatBtn/floatBtn.vue';

@Component({
  components: { FloatBtn, CommonComponents },
  mixins: [Gopage],
})
export default class InnerInformation extends Vue {
  // 左侧卡片可选内容
  private dataList: any = [];

  private dataGovList: any = [{ label: '安全生产综合监管系统' }, { label: '高危企业风险监测预警系统' }, { label: '安全生产教育培训监管系统' }, { label: '安全生产责任清单制管理系统' }];
  private dataEntList: any = [{ label: '履职清单管理系统' }, { label: '双重预防工作管理系统' }, { label: '隐患排查治理系统' }, { label: '设备全生命周期管理系统' }, { label: '设备设施巡检管理系统' }, { label: '职工教育培训管理系统' }];

  // 安全生产综合监管系统
  private dataForm: any = {
    commonPic: require('@/base/productsPic/innerPic/anquanshengchanCopy.png'),
    commonTitle: '安全生产综合监管系统',
    commonContent: '安全生产综合监管系统是在整合应急管理部门和其他相关部门各项业务信息的基础上，构建各业务域主题应用，实现数据一次采集、多点应用，打破部门间的业务阻隔，充分发挥信息系统的优势，提升安全生产监管水平和服务能力',
    commonBgPic: require('@/base/productsPic/innerPic/right-bg.png'),
    commonDetailsPic: require('@/base/productsPic/innerPic/right-detailed2x.png'),
    dataList: [
      { title: 'PART.1', content: '重点行业领域监管' },
      { title: 'PART.2', content: '一般企业监管' },
      { title: 'PART.3', content: '安全风险管控' },
      { title: 'PART.4', content: '隐患排查治理' },
    ],
  };

  // 高危企业风险监测预警系统
  private highRiskDataForm: any = {
    commonPic: require('@/base/productsPic/innerPic/anquanshengchan.png'),
    commonTitle: '高危企业风险监测预警系统',
    commonContent: '高危企业风险监测预警系统是利用物联网、视联网技术，实时采集汇总高危企业安全风险数据，通过分析研判实现安全风险分级预警，建立预警处置闭环管理功能，确保企业预警处置到位、各级监管部门监管工作到位。',
    commonBgPic: require('@/base/productsPic/innerPic/right-bg.png'),
    commonDetailsPic: require('@/base/productsPic/innerPic/high-detailed2x.png'),
    dataList: [
      { title: 'PART.1', content: '企业风险信息实时采集' },
      { title: 'PART.2', content: '安全风险研判与分级预警' },
      { title: 'PART.3', content: '预警信息处置闭环管理' },
    ],
  };

  // 安全生产教育培训监管系统
  private safeProductionDataForm: any = {
    commonPic: require('@/base/productsPic/innerPic/safe-production.png'),
    commonTitle: '安全生产教育培训监管系统',
    commonContent: '安全生产教育培训监管系统是利用监管部门考试发证系统信息和企业三项人员信息，通过信息研判，确保培训计划、考试计划编制合理，确保企业三项人员培训到位、证件有效，为监管部门执法工作提供案源线索，实现人员资质风险的源头管控。',
    commonBgPic: require('@/base/productsPic/innerPic/right-bg.png'),
    commonDetailsPic: require('@/base/productsPic/innerPic/safe-detailed2x.png'),
    dataList: [
      { title: 'PART.1', content: '考试发证信息、企业三项人员信息数据整合' },
      { title: 'PART.2', content: '信息分析研判预警' },
      { title: 'PART.3', content: '为监管部门执法工作提供案源线索' },
    ],
  };

  // 安全生产责任清单制管理系统
  private inventorySystemDataForm: any = {
    commonPic: require('@/base/productsPic/innerPic/quyeqingdanCopy.png'),
    commonTitle: '安全生产责任清单制管理系统',
    commonContent: '安全生产责任清单是根据《地方党政领导干部安全生产责任制规定》的规定，梳理各级党委、政府和相关监管部门的责任清单，结合年度安全生产目标考核要求和各项临时工作等，将责任和要求转化为工作任务，对工作任务实施过程跟踪管理，实现安全生产工作考核工作实时化。',
    commonBgPic: require('@/base/productsPic/innerPic/right-bg.png'),
    commonDetailsPic: require('@/base/productsPic/innerPic/inventory-detailed2x.png'),
    dataList: [
      { title: 'PART.1', content: '责任清单编制' },
      { title: 'PART.2', content: '年度考核目标和临时任务' },
      { title: 'PART.3', content: '工作任务分解' },
      { title: 'PART.4', content: '工作过程实时反馈跟踪' },
      { title: 'PART.5', content: '安全生产工作实时考核' },
    ],
  };

  // 产品中心履职清单管理系统
  private performanceListDataForm: any = {
    commonPic: require('@/base/productsPic/innerPic/lvzhiqingdan.png'),
    commonTitle: '履职清单管理系统',
    commonContent: '企业履职清单是根据《安全生产法》对企业安全生产各级、各岗位的安全生产管理责任进行梳理，结合年度安全生产目标责任考核要求，建立履职清单、转化为工作任务，根据各项任务完成情况反馈，进行过程跟踪、实现临期提醒、超期报警，避免工作疏漏导致的安全生产工作履职不到位情况的发生。',
    commonBgPic: require('@/base/productsPic/innerPic/right-bg.png'),
    commonDetailsPic: require('@/base/productsPic/innerPic/performance-detailed2x.png'),
    dataList: [
      { title: 'PART.1', content: '责任清单编制' },
      { title: 'PART.2', content: '年度考核目标和临时任务' },
      { title: 'PART.3', content: '工作任务分解' },
      { title: 'PART.4', content: '工作过程实时反馈跟踪' },
      { title: 'PART.5', content: '安全生产工作实时考核' },
    ],
  };

  // 双重预防工作管理系统
  private doublePreventionDataForm: any = {
    commonPic: require('@/base/productsPic/innerPic/double-prevention.png'),
    commonTitle: '双重预防工作管理系统',
    commonContent: '双重预防工作管理系统是企业以安全生产标准化为基本管理框，以安全风险分级管控和隐患排查治理为工作主线，贯穿企业安全生产管理工作方方面面，通过风险管控和隐患排查两道防线，有效预防安全生产事故的发生。',
    commonBgPic: require('@/base/productsPic/innerPic/right-bg.png'),
    commonDetailsPic: require('@/base/productsPic/innerPic/double-details2x.png'),
    dataList: [
      { title: 'PART.1', content: '安全风险辨识与分级' },
      { title: 'PART.2', content: '安全风险管控清单' },
      { title: 'PART.3', content: '安全风险四色图' },
      { title: 'PART.4', content: '隐患排查标准管理' },
      { title: 'PART.5', content: '隐患排查治理闭环管理' },
    ],
  };

  // 隐患排查治理系统
  private troubleshootingDataForm: any = {
    commonPic: require('@/base/productsPic/innerPic/troubleshooting.png'),
    commonTitle: '隐患排查治理系统',
    commonContent: '隐患排查治理体系建设包括隐患排查标准和制度、隐患排查治理信息化工具，隐患排查治理系统通过隐患排查治理制度流程化、隐患排查标准化、隐患治理严格实施闭环管理，确保每个隐患都能得到有效治理，避免因为隐患没有发现、没有即时治理导致的事故发生。',
    commonBgPic: require('@/base/productsPic/innerPic/right-bg.png'),
    commonDetailsPic: require('@/base/productsPic/innerPic/troubleshooting2x.png'),
    dataList: [
      { title: 'PART.1', content: '隐患排查制度管理' },
      { title: 'PART.2', content: '隐患排查标准管理' },
      { title: 'PART.3', content: '隐患治理闭环管理' },
      { title: 'PART.4', content: '隐患排查治理台账' },
    ],
  };

  // 设备全生命周期管理系统
  private lifeCycleDataForm: any = {
    commonPic: require('@/base/productsPic/innerPic/life-cycle.png'),
    commonTitle: '设备全生命周期管理系统',
    commonContent: '映设备的增加、减少及相关变动情况，及时提醒设备保养及校准，提供针对设备的全生命周期跟踪管理，帮助企业更有效、更全面地管理设备。对资产实物从购置、领用、转移、盘点、维修、报废等方面进行全方位准确监管，使企业更轻松、更有效地管理固定资产，各级相关管理人员及有关领导可快速查询、统计资产情况，以实现资源合理配置，并为决策提供依据。',
    commonBgPic: require('@/base/productsPic/innerPic/right-bg.png'),
    commonDetailsPic: require('@/base/productsPic/innerPic/life-cycle-details2x.png'),
    dataList: [
      { title: 'PART.1', content: '运行管理', text1: '设备运行管理', text2: '养护管理', text3: '保养巡检', text4: '设备管理', text5: '采购管理', text6: '设备台账' },
      { title: 'PART.2', content: '运行管理', text1: '设备报修', text2: '处理分配', text3: '维修工单', text4: '配件工时', text5: '维修知识库' },
      { title: 'PART.3', content: '运行管理', text1: '工单分配', text2: '抢单', text3: '自定义工单', text4: '工单调整' },
      { title: 'PART.4', content: '运行管理', text1: '运行统计分析', text2: '维修保养统分析', text3: '备品备件统计分析', text4: '工时统计分析' },
      { title: 'PART.5', content: '运行管理', text1: '出入库管理', text2: '物料设置', text3: '关联设置', text4: '备品备件' },
      { title: 'PART.6', content: '运行管理', text1: '设备与巡点检', text2: '隐患排查', text3: '检维修记录相关联做到使用记录留痕，有据可依' },
    ],
  };

  // 设备设施巡检管理系统
  private equipmentInspectionDataForm: any = {
    commonPic: require('@/base/productsPic/innerPic/equipment-inspection.png'),
    commonTitle: '设备设施巡检管理系统',
    commonContent: '设备巡检管控系统是基层员工班内巡检的主要工具，员工采用手机端APP实现巡检点扫码（二维码、RFID、蓝牙）自动记录巡检时间和巡检部位，通过对应的巡检标准逐项确认，发现问题可以通过拍照、记录问题情况进行问题上报，通过维修处置流程实现问题的闭环处理。',
    commonBgPic: require('@/base/productsPic/innerPic/right-bg.png'),
    commonDetailsPic: require('@/base/productsPic/innerPic/equipment-inspection-details2x.png'),
    dataList: [
      { title: 'PART.1', content: '巡检线路管理' },
      { title: 'PART.2', content: '巡检点管理' },
      { title: 'PART.3', content: '巡检标准管理' },
      { title: 'PART.4', content: '巡检排班管理' },
      { title: 'PART.5', content: '现场巡检' },
      { title: 'PART.6', content: '巡检工作考核' },
    ],
  };

  // 职工教育培训管理系统
  private employeeEducationDataForm: any = {
    commonPic: require('@/base/productsPic/innerPic/employee-education.png'),
    commonTitle: '职工教育培训管理系统',
    commonContent: '职工教育培训管理系统包括安全生产知识、岗位理论知识和岗位操作技能三个方面，通过手机端网络技术，实现网上培训、网上考核、网上评价。',
    commonBgPic: require('@/base/productsPic/innerPic/right-bg.png'),
    commonDetailsPic: require('@/base/productsPic/innerPic/employee-education-details2x.png'),
    dataList: [
      { title: 'PART.1', content: '网上培训' },
      { title: 'PART.2', content: '网上考核' },
      { title: 'PART.3', content: '网上评价' },
    ],
  };

  // 左侧选择的某类
  private chooseLabel: any = '';
  /**
   * @desc Vue 创建数据生命周期，获取验证码
   * @param1 暂无
   * @returns 暂无
   */
  created() {}

  // 左侧系统类别选择器
  changeLabel(data: any, type: any) {
    window.scrollTo({ top: 0 });
    this.chooseLabel = data;
    if (type === 'gov') {
      this.dataList = this.dataGovList;
    } else {
      this.dataList = this.dataEntList;
    }
    let newObj: any = {};
    switch (data) {
      case '安全生产综合监管系统':
        newObj = this.dataForm;
        break;
      case '高危企业风险监测预警系统':
        newObj = this.highRiskDataForm;
        break;
      case '安全生产教育培训监管系统':
        newObj = this.safeProductionDataForm;
        break;
      case '安全生产责任清单制管理系统':
        newObj = this.inventorySystemDataForm;
        break;
      case '履职清单管理系统':
        newObj = this.performanceListDataForm;
        break;
      case '双重预防工作管理系统':
        newObj = this.doublePreventionDataForm;
        break;
      case '隐患排查治理系统':
        newObj = this.troubleshootingDataForm;
        break;
      case '设备全生命周期管理系统':
        newObj = this.lifeCycleDataForm;
        break;
      case '设备设施巡检管理系统':
        newObj = this.equipmentInspectionDataForm;
        break;
      case '职工教育培训管理系统':
        newObj = this.employeeEducationDataForm;
        break;
    }
    (this.$refs['showComponents'] as any).showComponent(data, newObj);
  }

  // 左侧系统类别选择器
  changeTwoLabel(data: any) {
    // window.scrollTo({ top: 0 });
    this.chooseLabel = data;
    let newObj: any = {};
    switch (data) {
      case '安全生产综合监管系统':
        newObj = this.dataForm;
        break;
      case '高危企业风险监测预警系统':
        newObj = this.highRiskDataForm;
        break;
      case '安全生产教育培训监管系统':
        newObj = this.safeProductionDataForm;
        break;
      case '安全生产责任清单制管理系统':
        newObj = this.inventorySystemDataForm;
        break;
      case '履职清单管理系统':
        newObj = this.performanceListDataForm;
        break;
      case '双重预防工作管理系统':
        newObj = this.doublePreventionDataForm;
        break;
      case '隐患排查治理系统':
        newObj = this.troubleshootingDataForm;
        break;
      case '设备全生命周期管理系统':
        newObj = this.lifeCycleDataForm;
        break;
      case '设备设施巡检管理系统':
        newObj = this.equipmentInspectionDataForm;
        break;
      case '职工教育培训管理系统':
        newObj = this.employeeEducationDataForm;
        break;
    }
    (this.$refs['showComponents'] as any).showComponent(data, newObj);
  }

  closedPage() {
    this.$emit('on-response');
  }
}
