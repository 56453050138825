
import { Component, Vue } from 'vue-property-decorator';
import { Gopage } from '@/mixins/gopage';

@Component({
  components: {},
  mixins: [Gopage],
})
export default class Login extends Vue {
  /**
   * @desc Vue 创建数据生命周期，获取验证码
   * @param1 暂无
   * @returns 暂无
   */
  created() {}

  /**
    @description: 点击系统模块打开内部详细信息组件事件
    @description: 此事件在productCenter文件中
    @author: cxf
    @update: cxf 2023/3/28 10:35
 **/
  openTheInfo(classification: any) {
    this.$emit('central-response', classification, 'ent');
  }
}
