
import { Component, Vue } from 'vue-property-decorator';
import { Gopage } from '@/mixins/gopage';

@Component({
  components: {},
  mixins: [Gopage],
})
export default class Login extends Vue {
  /**
   * @desc Vue 创建数据生命周期，获取验证码
   * @param1 暂无
   * @returns 暂无
   */
  created() {}

  openTheInfo(classification: any) {
    this.$emit('central-response', classification, 'gov');
  }
}
